import React from 'react';
import {TextField, Select, InputLabel, Button, MenuItem, FormHelperText, FormControl} from "@mui/material";
import { useFormik } from 'formik';
import createFinancialEntry from "./createFinancialEntry";

import financialValidationSchema from "./financialValidationSchema";

const FinancialForm = (props: any) => {

  const formik = useFormik({
    initialValues: {
      id: "",
      objective: "",
      amount: "",
      creditorId: "",
      creditor: "",
      debtor: "",
      invoiced: false,
      payed: false,
      cleared: false,
      date: "",
      receiptUrl: "",
    },
    validationSchema: financialValidationSchema,
    onSubmit: (values, helpers) => {
      createFinancialEntry(values, props.setNotificationOpen)
        .then((resp: string) => {
          console.log("onSubmit response:", resp);
          if (resp) {
            props.setNotificationOpen({
              open: true,
              message: "Eintrag erfolgreich erstellt",
              type: "success"
            });
            helpers.resetForm();
          }
        })
    },
  })

  return (
    <form id={"financial-form"} style={{position: "relative", width:"90%", marginBottom: "45px"}} className={"f-col-fs-fs"} onSubmit={formik.handleSubmit}>
      <TextField
        id={"objective"}
        type={"text"}
        label={"Ausgabegrund"}
        placeholder={"Bier"}
        fullWidth
        error={formik.touched.objective && Boolean(formik.errors.objective)}
        helperText={formik.touched.objective && formik.errors.objective}
        {...formik.getFieldProps("objective")}
      />
      <TextField
        id={"amount"}
        type={"text"}
        label={"Betrag"}
        placeholder={"5.99€"}
        fullWidth
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        {...formik.getFieldProps("amount")}
      />
      <FormControl fullWidth sx={{minWidth: 120}}>
        <InputLabel id={"creditor"}>Zahlende/r</InputLabel>
        <Select
          labelId={"creditor"}
          id={""}
          variant={"standard"}
          error={formik.touched.creditor && Boolean(formik.errors.creditor)}
          {...formik.getFieldProps("creditor")}
        >
          <MenuItem value={"Katrin"}>Katrin</MenuItem>
          <MenuItem value={"Marco"}>Marco</MenuItem>
          <MenuItem value={"Pascal"}>Pascal</MenuItem>
          <MenuItem value={"Quentin"}>Quentin</MenuItem>
          <MenuItem value={"Woife"}>Woife</MenuItem>
        </Select>
        <FormHelperText>{formik.errors.creditor}</FormHelperText>
      </FormControl>
      <FormControl fullWidth sx={{minWidth: 120}}>
        <InputLabel id={"debtor"}>Schuldner</InputLabel>
        <Select
          labelId={"debtor"}
          id={""}
          variant={"standard"}
          error={formik.touched.debtor && Boolean(formik.errors.debtor)}
          {...formik.getFieldProps("debtor")}
        >
          <MenuItem value={"Katrin"}>Katrin</MenuItem>
          <MenuItem value={"Marco"}>Marco</MenuItem>
          <MenuItem value={"Pascal"}>Pascal</MenuItem>
          <MenuItem value={"Quentin"}>Quentin</MenuItem>
          <MenuItem value={"Woife"}>Woife</MenuItem>
        </Select>
        <FormHelperText>{formik.errors.debtor}</FormHelperText>
      </FormControl>
      {/*<TextField*/}
      {/*  id={"creditor"}*/}
      {/*  type={"text"}*/}
      {/*  label={"Zahlende/r"}*/}
      {/*  placeholder={"Peter Pan"}*/}
      {/*  fullWidth*/}
      {/*  error={formik.touched.creditor && Boolean(formik.errors.creditor)}*/}
      {/*  helperText={formik.touched.creditor && formik.errors.creditor}*/}
      {/*  {...formik.getFieldProps("creditor")}*/}
      {/*/>*/}
      {/*<TextField*/}
      {/*  id={"debtor"}*/}
      {/*  type={"text"}*/}
      {/*  label={"Schuldner"}*/}
      {/*  placeholder={"Arielle"}*/}
      {/*  fullWidth*/}
      {/*  error={formik.touched.debtor && Boolean(formik.errors.debtor)}*/}
      {/*  helperText={formik.touched.debtor && formik.errors.debtor}*/}
      {/*  {...formik.getFieldProps("debtor")}*/}
      {/*/>*/}
      <Button variant={"contained"} type={"submit"}>Submit</Button>
    </form>
  )
}

export default FinancialForm;