import SignUpForm from "./SignUpForm";
import Hero from "../../Hero";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface Notification {
  open: boolean,
  message: string,
  type: any
}

const SignUpPage = () => {

  const [notification, setNotification] = useState<Notification>({open: false, message: "", type: "success"})

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({
      open: false,
      message: "",
      type: "success"
    });
  };

  return (
    <div className={"page-content-root"}>
      <Hero title={"Registrieren"} />
      <SignUpForm setNotification={setNotification} />
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert variant={"filled"} icon={<CheckIcon fontSize="inherit" />} severity={notification.type} onClose={handleSnackbarClose}>
          {notification.message}
        </Alert>
      </Snackbar>
      <Link to={"/signin"}>
        Anmelden
      </Link>
    </div>
  )
}

export default SignUpPage;