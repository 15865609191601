import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {Button, TextField} from "@mui/material";
import signUpValidationSchema from "./signUpValidationSchema";
import signUp from "../../adaptors/firebase/firebaseSignUp";

import firebaseGetAllUsernames from "../../adaptors/firebase/firebaseGetAllUsernames";
import createUserDocument from "../../adaptors/firebase/createUserDocument";

interface Input {
  id: string,
  type: string,
  label: string,
  placeholder: string,
  fullwidth: boolean,
  error: boolean,
  helperText: string
}

interface InputValues {
  name: string,
  email: string,
  password: string,
}

interface Notification {
  open: boolean,
  message: string,
  type: any
}

const SignUpForm = (props: {setNotification: (data: Notification) => void}) => {
  const inputs: Input[] = [
    {
      id: "name",
      type: "text",
      label: "Name",
      placeholder: "Arielle",
      fullwidth: true,
      error: false,
      helperText: ""
    },
    {
      id: "email",
      type: "text",
      label: "E-Mail",
      placeholder: "e.g. arielle@pegasus.com",
      fullwidth: true,
      error: false,
      helperText: ""
    },
    {
      id: "password",
      type: "password",
      label: "Passwort",
      placeholder: "*********",
      fullwidth: true,
      error: false,
      helperText: ""
    },
    {
      id: "passwordRepeat",
      type: "password",
      label: "Passwort wiederholen",
      placeholder: "*********",
      fullwidth: true,
      error: false,
      helperText: ""
    }
  ]
  const [usernames, setUsernames] = useState<string[]>([])
  const formik = useFormik({
    initialValues: {name: "", email: "", password: "", passwordRepeat: ""},
    validationSchema: signUpValidationSchema(usernames),
    onSubmit: values => handleSignUp(values)
  })

  const navigate = useNavigate();

  useEffect(() => {
    firebaseGetAllUsernames()
      .then(resp => setUsernames(resp))
      .catch(e => console.log("Error during fetching usernames", e))
  }, [])

  const handleSignUp = (values: InputValues) => {
    try {
      signUp(values)
        .then(resp => {
          if (resp.user) {
            const userData = {
              userId: resp.user.uid,
              username: values.name,
              email: values.email
            }
            createUserDocument(userData);
            props.setNotification({open: true, message: "Du hast dich erfolgreich registriert", type: "success"});
            navigate("/");
          }
        })
        .catch(e => {
          console.log(e)
          props.setNotification({open: true, message: "Beim registrieren ist ein Fehler aufgetreten." + e.message, type: "error"});
        })
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div style={{position: "relative", width: "90%"}}>
      <form onSubmit={formik.handleSubmit}>
        {
          inputs.map(input => (
            <TextField
              key={input.id}
              id={input.id}
              type={input.type}
              label={input.label}
              placeholder={input.placeholder}
              fullWidth={input.fullwidth}
              // @ts-ignore
              error={formik.touched[input.id] && Boolean(formik.errors[input.id])}
              // @ts-ignore
              helperText={formik.touched[input.id] && formik.errors[input.id]}
              {...formik.getFieldProps(input.id)}
            />
          ))
        }
        <Button variant={"contained"} type={"submit"}>Registrieren</Button>
      </form>
    </div>
  )
}

export default SignUpForm;