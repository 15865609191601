import { firestore, collection, doc, query, setDoc, getDoc, getDocs, updateDoc, where, limit, increment, orderBy, deleteDoc } from './'

/**
 * @active: boolean
 * @id: number,
 * @name: string,
 * @orderId: number,
 * @v2Name: string,
 * @version: string
 */
interface GetAllRooms {
    active: boolean,
    id: string,
    name: string,
    orderId: number,
    v2Name: string,
    v2id: string,
    version: string
    optOutPossible: boolean
}

interface GetRoomResponsibility {
    id: string,
    helpsOut: boolean,
    room: string
}

interface OptOutResponse {
    newUser: string,
    optOutPossible: boolean
}

const getCalendarWeek = () => {
    const firstJan: any = new Date(new Date().getUTCFullYear(), 0, 1);
    const day = firstJan.getDay();
    const diff = day === 0 ? 6 : day-1; // calculating the difference between day of first jan and the monday in that week
    const date2: any = new Date();
    const msecsPerDay = 24*3600*1000;
    const daysThisYear = (date2-firstJan)/msecsPerDay;
    const daysThisYearFilled = daysThisYear+diff; // needed because first week usually is not filled with days of this year
    return Math.floor((daysThisYearFilled)/7);
}

const getQuerySnapshot = async () => {
    const gquery = query(collection(firestore, 'Rooms'), where('version', '==', 'v2'));
    return await getDocs(gquery);
}

// const getDocument = (docPath: string): Promise<DocumentData> => {
//     const docRef = doc(firestore, docPath);
//     return getDoc(docRef);
// }

// const getDocField = async (docPath: string, fieldName: string): Promise<string> => {
//     const docRef = doc(firestore, docPath);
//     const document = await getDoc(docRef);
//     if (document.exists()) {
//         return document.data()[fieldName];
//     } else {
//         return new Promise((resolve, reject) => {reject("document does not exist")});
//     }
// }


const getAllRooms = async (): Promise<GetAllRooms[]> => {
    const response = await getQuerySnapshot();
    const respArray = response.docs.map((doc) => {
        const {active, id, name, orderId, v2Name, v2id, version, optOutPossible} = doc.data()
        return {active, id, name, orderId, v2Name, v2id, version, optOutPossible};
    })
    return respArray.sort((a, b) => a.orderId - b.orderId);
}


const getRoomResponsiblity = async (room: string): Promise<GetRoomResponsibility> => {
    console.log("starting getRoomResponsiblity")
    const roomDocQuery = query(collection(firestore, "Rooms"), where("v2id", "==", room), limit(1));
    const roomDoc = await getDocs(roomDocQuery)
    const roomDocId = roomDoc.docs[0].id;
    console.log(roomDocId)
    const gquery = query(collection(firestore, `Rooms/${roomDocId}/responsible`), limit(1));
    const responsibilityDoc = await getDocs(gquery);
    return responsibilityDoc.docs[0].data() as GetRoomResponsibility;
}


const getAllRoomResponsibilities = (rooms: string[]): Promise<any> => {
    const allRoomResponsibilities = rooms.map(async (room) => {
        let responsibility = await getRoomResponsiblity(room);
        // @ts-ignore
        responsibility['room'] = room;
        return responsibility;
    })
    return Promise.all(allRoomResponsibilities);
}

const optOut = async (roomId: string, userId: string): Promise<OptOutResponse> => {
    const cw = getCalendarWeek().toString();
    const docQuery = query(collection(firestore, "Rooms"), where("v2id", "==", roomId), limit(1));
    const docData = await getDocs(docQuery);
    const docId = docData.docs[0].id;
    const cwDoc = await getDoc(doc(firestore, `Rooms/${docId}/users/${userId}/responsibleDates/cw${cw}`));
    const cwDocExists = cwDoc.exists();
    //TODO: First check, if opt out is possible. Check Room-->optOutPossible and User-->available!
    /**
     * Reduce count of user
     */
    updateDoc(doc(firestore, `Rooms/${docId}/users/${userId}`), {
        count: increment(-1)
    }).catch((e) => {console.log(`Error in optOut while trying to decrement count for ${userId} in room ${docId}`, e.message)});

    /**
     * Update Field 'available' in user doc with value 'false'
     */
    // setDoc(doc(firestore, `Rooms/${docId}/users/${userId}`), {available: false}).catch(e => console.log("Error in optOut while trying to update 'available' field with value true:", e.message))
    updateDoc(doc(firestore, `Rooms/${docId}/users/${userId}`), {available: false})
      .catch(e => console.log("Error while update 'available' field to false:", e.message))

    /**
     * Change entry in 'responsibleDates'-->'cwXX' for user who wants to opt out to 'false'
     */
    //TODO: Here it throws an error if this field doesn't exist. Not failsafe
    if (cwDocExists) {
        updateDoc(doc(firestore, `Rooms/${docId}/users/${userId}/responsibleDates/cw${cw}`), {
            responsible: false
        }).catch(e => console.log("Error in 'optOut' while updating cw doc:", e.message));
    } else {
        setDoc(doc(firestore, `Rooms/${docId}/users/${userId}/responsibleDates/cw${cw}`), {
            responsible: false
        })
          .then(_ => console.log("Success"))
          .catch(e => console.log("Error in 'optOut' while setting cw doc:", e.message))
    }

    /**
     * Get all available users by value of fields 'available' and 'count'
     */
    const availableUserArray = (): Promise<any> => {
        const q = query(collection(firestore, `Rooms/${docId}/users`), where("available", "==", true), orderBy("count"));//, orderBy("count"), limit(1));
        return getDocs(q);
    }

    const newUser = await availableUserArray();
    const newUser2: string = newUser.docs[0].id;

    /**
     * Update documents for new user, namely "count", "responsible", "resposibleDates" and then delete old user and return if there are at least two available users
     */
    availableUserArray()
      .then((resp): void => {
        console.log(resp.docs)
        console.log(resp.docs[0].id)
        const u = resp.docs[0].id;
        updateDoc(doc(firestore, `Rooms/${docId}/users/${u}`), {count: increment(1)});
        setDoc(doc(firestore, `Rooms/${docId}/responsible/${u}`), {id: u, helpsOut: true});
        setDoc(doc(firestore, `Rooms/${docId}/users/${u}/responsibleDates/cw${cw}`), {responsible: true}, {merge: true});
        deleteDoc(doc(firestore, `Rooms/${docId}/responsible/${userId}`));
    })
      .catch(e => {console.log("Error in optOut while updating data for new user:", e.message)})

    const listOfAvailableUsers = await availableUserArray();
    console.log("array length:", listOfAvailableUsers.docs.length);
    if (listOfAvailableUsers.docs.length < 2) {
        console.log("length is lower than 2...")
        updateDoc(doc(firestore, `Rooms/${docId}`), {optOutPossible: false})
          .catch(e => console.log("Error while setting field 'optOutPossible' to false:", e.message))
    }

    const optOutPossible = listOfAvailableUsers.docs.length >= 2;
    return {newUser: newUser2, optOutPossible: optOutPossible};
}


export {getQuerySnapshot, getAllRooms, getRoomResponsiblity, getAllRoomResponsibilities, optOut};