import React from "react";
import {Snackbar, Alert} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface _Notification {
  data: {
    open: boolean,
    type: any,
    message: string,
  },
  setNotification: any
}

const Notification = (props: _Notification) => {
  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setNotification({
      open: false,
      message: "",
      type: "success"
    });
  };
  return (
    <Snackbar
      open={props.data.open}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert variant={"filled"} icon={<CheckIcon fontSize="inherit" />} severity={props.data.type} onClose={handleSnackbarClose}>
        {props.data.message}
      </Alert>
    </Snackbar>
  )
}

export default Notification;