import React, {useState, useEffect} from "react";
import {Box, Button, ButtonGroup, Typography} from "@mui/material"

import CleaningPlanStatistics from "./CleaningPlanStatistics";
import Hero from "../../Hero";

const StatisticsPage = () => {

  useEffect(() => {
    console.log("loaded statistics")
  }, []);

  return (
    <div className={"page-content-root"}>
      <Hero title={"Statistiken"} />
      <Box className={"statistics-selection"}>
        <ButtonGroup>
          <Button>Putzplan</Button>
          <Button disabled>Strom</Button>
          <Button disabled>Gas</Button>
        </ButtonGroup>
      </Box>
      <Box>
        <CleaningPlanStatistics />
      </Box>
    </div>
  )
}

export default StatisticsPage;