import {firestore, collection, where, getDocs, query} from "./"

const getAllActiveRoomDocIds = async (): Promise<string[]> => {
  const gquery = query(
    collection(firestore, "Rooms"),
    where("active", "==", true)
  );
  const activeRoomDocsQuery = await getDocs(gquery);
  const activeRoomDocs = activeRoomDocsQuery.docs;
  if (activeRoomDocs.length > 0) {
    return activeRoomDocs.map((roomDoc) => {
      return roomDoc.id;
    })
  } else {
    return []
  }
}

const getStuff = async () => {
  const docIds = await getAllActiveRoomDocIds();
  const z = docIds.map(async (docId: string): Promise<{}[]> => {
    let roomName: string;
    switch (docId) {
      case "bh1f":
        roomName = "EG"
        break
      case "bh2f":
        roomName = "OG"
        break
      case "cellar":
        roomName = "Keller"
        break
      case "kitchen":
        roomName = "Küche"
        break
      default:
        roomName = "Unbekannt"
        break
    }
    const gquery = query(collection(firestore, `Rooms/${docId}/users`));
    const d = await getDocs(gquery);
    const userDocs = d.docs;
    if (userDocs.length > 0) {
      return userDocs.map((userDoc): {user: string, count: number, room: string} => {
        return {user: userDoc.data().id, count: userDoc.data().count, room: roomName}
      })
    } else {
      return [{}]
    }
  })
  return Promise.all(z);
}

export {getStuff}