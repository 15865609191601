import {firestore, getDocs, collection, query, where, doc, updateDoc, setDoc, arrayUnion, arrayRemove} from "./"

interface _GetHighlightedDays {
  id: string,
  month: string,
  year: string
}

interface _SetHighlightedDays {
  id: string,
  month: string,
  year: string,
  day: number
}

const getAllCalendars = async (): Promise<any[]> => {
  const gquery = query(
    collection(firestore, "calendar"),
    where("id", "==", "calendars")
  );
  const calendarsDoc = await getDocs(gquery);
  if (calendarsDoc.docs.length > 0) {
    const calendarObj = calendarsDoc.docs[0].data().calendarIds.map((val: string, index: number) => {return {id: val, nameGer: calendarsDoc.docs[0].data().calendarNamesGer[index]}});
    return calendarObj;
  } else {
    return []
  }
}

const addHighlightedDay = async (id: string, month: string, year: string, day: number, action: string): Promise<boolean> => {
  const gquery = query(
    collection(firestore, "calendar"),
    where("id", "==", id),
    where("month", "==", month),
    where("year", "==", year)
  );
  let response: boolean = false
  const docs = await getDocs(gquery);
  if (docs.docs.length > 0) {
    const docId = docs.docs[0].id;
    const docRef = doc(firestore, `calendar/${docId}`);
    if (action === "add") {
      await updateDoc(docRef, {
        days: arrayUnion(day)
      })
    } else if (action === "remove") {
      await updateDoc(docRef, {
        days: arrayRemove(day)
      })
    }
  } else if (action === "add") {
    await setDoc(doc(collection(firestore, "calendar")), {
      id: id,
      month: month,
      year: year,
      days: [day]
    })
  } else {
    console.log("no document for ", id, " found")
  }
  return response
}

const removeHighlightedDay = async (): Promise<any> => {

}

const getHighlightedDays = async ({id, month, year}: _GetHighlightedDays): Promise<number[]> => {
  const gquery = query(
    collection(firestore, "calendar"),
    where("id", "==", id),
    where("month", "==", month),
    where("year", "==", year)
  )
  const docs = await getDocs(gquery);
  return docs.docs[0] ? docs.docs[0].data().days as number[] : [];
}

const getAllHighlightedDays = () => {

}

export {getAllCalendars, addHighlightedDay, getHighlightedDays, getAllHighlightedDays}