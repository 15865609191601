import {firestore, collection, getDocs, where, getAuth, query} from "./index"

const uidToUsername = async (setUsername: (username: string) => void): Promise<void> => {
  const auth = getAuth();
  const uid: string|undefined = auth.currentUser?.uid
  let username: string = "";
  try {
    const gquery = query(collection(firestore, "Users"), where("userId", "==", uid));
    const querySnapshot = await getDocs(gquery);
    username = querySnapshot.docs[0].data().username;
    setUsername(username);
  } catch (e) {
    console.log("Error in uidToUsername")
  }
}

export default uidToUsername;