import {useEffect, useState} from "react";
import {getCurrentUser} from "../firebase/firestoreAuth";
import {addAuthListener} from "./addAuthListener";
import {User} from "firebase/auth";

interface Auth {
  isLoading: boolean,
  user: User | null,
  role: number
}

// let useFbAuth;
const useFbAuth = (): Auth => {
  /**
   * Diese Idee stammt von LinkedIn Learning: https://www.linkedin.com/learning/firebase-essential-training/add-private-routes?resume=false&u=78741226
   */
  const [authInfo, setAuthInfo] = useState((): {isLoading: boolean, user: User | null, role: number} => {
    const user: User | null = getCurrentUser();
    const isLoading = !user;
    const role: number = 0;
    return {isLoading, user, role}
  })

  useEffect(() => {
    /**
     * Übergeben des Callbacks an "addAuthListener". Dieser holt die Rollen-ID von Firebase und das User-Objekt.
     * Das wird dann in den State authInfo mit "isLoading: false" gespeichert.
     */
    return addAuthListener((user: any): void => {
      if (user) {
        user.getIdTokenResult().then((resp: any) => {
          //TODO: wenn ein user sich registriert, ist noch nicht initial ein custom claim hinterlegt. das führt dazu,
          // dass 'setAuthInfo' nicht ausgeführt wird. Zuletzt konnte sich der user deshalb nicht anmelden.
          // Das, weil das user object nicht gesetzt wurde.

          const roleResponse = resp.claims.role.id;
          let role: number = 0;
          if (typeof roleResponse === "number") {
            role = roleResponse;
          }
          // const role = 5;
          setAuthInfo({isLoading: false, user, role});
        })
      } else {
        const role: number = 0;
        setAuthInfo({isLoading: false, user, role});
        console.log("no user object. in useAuth")
      }
    })
  }, [])

  return authInfo
}

export {useFbAuth};