import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    required: "Pflichtfeld, bitte einen Wert eingeben",
    notType: "Bitte Punkt statt Komma und nur Zahlen eingeben"
  },
  number: {
    moreThan: "Bitte nur Zahlen größer als 0 eingeben"
  }
})

const financialValidationSchema = Yup.object({
  objective: Yup.string().required(),
  amount: Yup.number().moreThan(0).required(),
  creditor: Yup.string().required(),
  debtor: Yup.string().notOneOf([Yup.ref("creditor")], "Zahlender und Schuldner dürfen nicht gleich sein").required(),
})

export default financialValidationSchema;