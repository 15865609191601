import * as Yup from "yup";

const signUpValidationSchema = (usernames: string[]) => {
  return Yup.object({
    name: Yup.string().required().notOneOf(usernames, "Dieser Benutzername ist bereits vergeben").trim("Keine Leerzeichen erlaubt").strict(),
    email: Yup.string().email().required().trim("Keine Leerzeichen erlaubt"),
    password: Yup.string().min(8, "Das Passwort muss mindestens acht Zeichen lang sein").required(),
    passwordRepeat: Yup.string().oneOf([Yup.ref("password")], "Passwords does not match").required()
  })
}


export default signUpValidationSchema;