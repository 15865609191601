import React from "react";
import {Box, Paper, Button, Typography} from "@mui/material";

import "./destination.component.css";

interface DestinationInt {
  id: string,
  name: string,
  nights: number,
  travelBy: string,
  days: string
}

const Destination = (props: DestinationInt) => {
  return (
    <Box key={props.id} className={"destination-box"}>
      {props.id !== "munich" && <Box className={"connection-line"}></Box>}
      <Paper>
        <Typography variant={"body1"} className={"destination-name"}>{props.name}</Typography>
        <Typography variant={"body1"} className={"anreise"}>Anreise: {props.travelBy}</Typography>
        {props.id !== "munich" && <Typography variant={"body1"} className={"destination-nights"}>Nächte: {props.nights}</Typography>}
      </Paper>
    </Box>
  )
}

export default Destination;