import React from "react";
import {Button, Typography} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

import HouseRectangle from "./HouseRectangle";


const RoomOptionModal = (props: { roomName: string, modalWidth: number; modalHeight: number; bg: string; topPos: number; leftPos: number, optOut: any, close: any, optOutPossible: boolean}): JSX.Element => {


  return (
    <HouseRectangle id={"room-options"} top={props.topPos} left={props.leftPos} rectWidth={props.modalWidth} rectHeight={props.modalHeight} position={"absolute"} zIndex={2} name={"room-options"}>
      <>
        <Typography variant={"body1"} sx={{position: "absolute", top: 5, left: 5, color: "#89849c"}}>{props.roomName}</Typography>
        <Button variant={"contained"} disabled={!props.optOutPossible} sx={{position: "absolute"}} onClick={props.optOut}>Mich Abmelden</Button>
        <CancelIcon sx={{position: "absolute", right: 0, fontSize: 35}} onClick={props.close} />
      </>
    </HouseRectangle>
  )
}

export default RoomOptionModal;