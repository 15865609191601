import React, {useState} from "react";
import {Typography} from "@mui/material";
import HouseRectangle from "./HouseRectangle";
import RoomOptionModal from "./RoomOptionModal";
import {optOut} from "../../adaptors/firebase/firestoreCleaningPlan";
import {useFbAuth} from "../../adaptors/auth/useAuth";
import Notification from "../../modules/notification/Notification";

interface houseSizeData {
  roofWidth: number,
  roofHeigth: number,
  houseWidth: number,
  firstFloorHeight: number,
  firstFloorWidth: number,
  secondFloorHeight: number,
  kitchenWidth: number,
  kitchenHeight: number,
  basementFloorHeight: number,
  rectRadius: number
}

interface GetAllRooms {
  active: boolean,
  id: string,
  name: string,
  orderId: number,
  v2Name: string,
  v2id: string,
  version: string
  optOutPossible: boolean
}

interface _Notification {
  open: boolean,
  message: string,
  type: any
}

const House = (props: {houseSizeData: houseSizeData, respData: any, setRespData: any, roomsData: GetAllRooms[]}) => {
  //setTop: (...args: any[]) => void
  const {user, role} = useFbAuth();
  const houseSizeData = props.houseSizeData;
  const responsibilityData = props.respData;
  const [top, setTop] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [clickedRoom, setClickedRoom] = useState("");
  const [clickedRoomId, setClickedRoomId] = useState("");
  const [clickedUserId, setClickedUserId] = useState("");
  const [optOutPossible, setOptOutPossible] = useState(false);
  const [optOutClickedArray, setOptOutClickedArray] = useState([
    {
      id: "second-floor",
      value: false
    },
    {
      id: "first-floor",
      value: false
    },
    {
      id: "kitchen",
      value: false
    },
    {
      id: "basement",
      value: false
    },
  ]);
  const [notification, setNotification] = useState<_Notification>({
    open: false,
    message: "",
    type: "success"
  });

  const toTitleCase = (string: string): string => {
    return string.slice(0, 1).toUpperCase() + string.slice(1);
  }

  //region MOVE TO useCleaningPlan
  const handleRoomClick = ({target}: any): void => {
    // tried to avoid 'any' from the line above. No success
    if (user && role >= 5) { // 5, weil der Standard User das Freigabelevel 5 hat
      const parentEl: HTMLElement = target.closest(".room-div");
      const roomId: string = parentEl.id;
      const userId: string = props.respData[props.respData.findIndex((entry: { room: string; }) => entry.room === roomId)].id;
      // Splitted the previous and next line due to learning purposes: Type HTMLElement
      const roomName: string = parentEl.getAttribute("data-name") ? parentEl.getAttribute("data-name") as string : "Unbekannter Raum";
      let newOptOutPossible: boolean;

      switch (roomId) {
        case "first-floor":
        case "kitchen": setTop(houseSizeData.roofHeigth + houseSizeData.secondFloorHeight); break;
        case "basement": setTop(houseSizeData.roofHeigth + houseSizeData.secondFloorHeight + houseSizeData.firstFloorHeight); break;
        case "second-floor": setTop(houseSizeData.roofHeigth); break;
        default: setTop(0)
      }
      const optOutClicked = optOutClickedArray[optOutClickedArray.findIndex(el => el.id === roomId)].value;
      if (optOutClicked) {
        newOptOutPossible = optOutPossible;
      } else {
        newOptOutPossible = props.roomsData[props.roomsData.findIndex(el => el.v2id === roomId)].optOutPossible;
      }

      setClickedRoom(roomName);
      setClickedRoomId(roomId);
      setClickedUserId(userId);
      setOptOutPossible(newOptOutPossible);
      revealRoomOptions();
    } else {
      console.log("not authed to reveal options modal");

    }
  }

  const handleOptOut = () => {
    const newOptOutClickedArray = [...optOutClickedArray];
    newOptOutClickedArray[newOptOutClickedArray.findIndex(el => el.id === clickedRoomId)].value = true;
    optOut(clickedRoomId, clickedUserId)
      .then((resp) => {
        if (resp) {
          console.log("Response of firestoreCleaningPlan:", resp);
          console.log(clickedRoomId);
          const respData = [...props.respData];
          respData[respData.findIndex(el => el.room === clickedRoomId)].id = resp.newUser;
          props.setRespData(respData);
          setOptOutPossible(resp.optOutPossible);
        } else {
          console.log("schicht im schacht");
          setOptOutPossible(false);
        }
      })
      .catch(e => console.log("Error in handleOptOut:", e))
      .finally(() => {setOptOutClickedArray(newOptOutClickedArray)})
    closeRoomOptions();
  }

  const revealRoomOptions = (): void => {
    setIsVisible(true);
  }
  const closeRoomOptions = (): void => {
    setIsVisible(false);
    // setClickedRoomId("");
    // setClickedRoom("");
    // setClickedUserId("");
  }
  //endregion

  const lawnPoints = (): string => {
    let points = "";
    let xValue = -1000;
    let yValue = 8;
    for (let i = 1; i < 1000; i++) {
      if (i % 2 === 0) {
        points += `${xValue}, ${yValue} `;
        yValue = 8;
      } else {
        points += `${xValue}, ${yValue} `;
        yValue = 0;
      }
      xValue += 3;
    }
    return points;
  }

  const SecondFloor = (props: {roomName: string}): JSX.Element => (
    <HouseRectangle id={"second-floor"} className={"room-div"} top={0} left={0} rectWidth={300} rectHeight={110} position={"relative"} zIndex={0} name={props.roomName} clickHandler={handleRoomClick}>
    {/*<HouseRectangle id={"svg-house-second-floor"} className={"room-div"} top={0} left={0} rectWidth={houseSizeData.houseWidth} rectHeight={houseSizeData.secondFloorHeight} position={"relative"} zIndex={0} name={"second-floor"} clickHandler={handleRoomClick}>*/}
      <>
        <Typography variant="body1" style={{position: "absolute", top: 5, left: 5, color: "#89849c"}}>{props.roomName}</Typography>
        <Typography variant="h5" style={{position: "absolute", top: 40, left: 100}}>{toTitleCase(responsibilityData[responsibilityData.findIndex((responsibleObj: any) => responsibleObj.room === "second-floor")].id)}</Typography>
      </>
    </HouseRectangle>
  )

  const FirstFloor = (props: {roomName: string}): JSX.Element => (
    <HouseRectangle id={"first-floor"} className={"room-div"} top={0} left={0} rectWidth={houseSizeData.firstFloorWidth} rectHeight={houseSizeData.firstFloorHeight} position={"relative"} zIndex={0} name={"Erdgeschoss"} clickHandler={handleRoomClick}>
    {/*<HouseRectangle id={"svg-house-first-floor-hallway"} className={"room-div"} top={0} left={0} rectWidth={houseSizeData.firstFloorWidth} rectHeight={houseSizeData.firstFloorHeight} position={"relative"} zIndex={0} name={"first-floor"} clickHandler={handleRoomClick}>*/}
      <>
        <Typography variant="body1" style={{position: "absolute", left: 5, color: "#89849c"}}>{props.roomName}</Typography>
        <Typography variant="h5" style={{position: "absolute", left: 60, top: 38}}>{toTitleCase(responsibilityData[responsibilityData.findIndex((responsibleObj: any) => responsibleObj.room === "first-floor")].id)}</Typography>
      </>
    </HouseRectangle>
  )

  const Kitchen = (props: {roomName: string}): JSX.Element => (
    //props: {roomName: string}
    <HouseRectangle id={"kitchen"} className={"room-div"} top={0} left={0} rectWidth={houseSizeData.kitchenWidth} rectHeight={houseSizeData.firstFloorHeight} position={"relative"} zIndex={0} name={"Küche"} clickHandler={handleRoomClick}>
      <>
        <Typography variant="body1" style={{position: "absolute", left: 5, color: "#89849c"}}>{props.roomName}</Typography>
        <Typography variant="h5" style={{position: "absolute", left: 15, top: 38}}>{toTitleCase(responsibilityData[responsibilityData.findIndex((responsibleObj: any) => responsibleObj.room === "kitchen")].id)}</Typography>
      </>
    </HouseRectangle>
  )

  const Basement = (props: {roomName: string}): JSX.Element => (
    <HouseRectangle id={"basement"} className={"room-div"} top={0} left={0} rectWidth={houseSizeData.houseWidth} rectHeight={houseSizeData.basementFloorHeight} position={"relative"} zIndex={0} name={"Keller"} clickHandler={handleRoomClick}>
      <>
        <Typography variant="body1" style={{position: "absolute", left: 5, color: "#6b6679"}}>{props.roomName}</Typography>
        <Typography variant="h5" style={{position: "absolute", left: 100, top: 38}}>{toTitleCase(responsibilityData[responsibilityData.findIndex((responsibleObj: any) => responsibleObj.room === "basement")].id)}</Typography>
      </>
    </HouseRectangle>
  )

  return (
    <div className="house-div" style={{position: "relative", width: houseSizeData.houseWidth}}>
      <div id="svg-house-roof" style={{margin: 0, padding: 0, position: "relative", display: "block", width: "100%", height: houseSizeData.roofHeigth, zIndex: 2, overflow: "visible"}}>
        <svg overflow={"visible"} width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" >
          <defs>
            <filter id="shadow" x="-20%" y="-20%" width="200%" height="200%">
              <feDropShadow dx="7" dy="7" stdDeviation="3" floodColor="grey" floodOpacity="0.5" />
            </filter>
            <filter id="sun-shadow">
              <feDropShadow dx="0" dy="0" stdDeviation="5" floodColor="yellow" floodOpacity="0.5" />
            </filter>
          </defs>
          <circle id={"smoke"} cx={95} cy={75} r={5} />
          <circle id={"smoke"} cx={90} cy={50} r={8} />
          <circle id={"smoke"} cx={105} cy={25} r={3} />
          <circle id={"sun"} cx={300} cy={20} r={15} />
          <rect x={80} y={10} width={35} height={50} stroke="#AAA" fill="#EEE" color="#FFFFFF" strokeWidth="1" />
          <path d={`
              M -10 ${houseSizeData.roofHeigth}
              A 4 4 0 0 1 -12 ${houseSizeData.roofHeigth-7}
              L ${houseSizeData.roofWidth/2-5} 5
              A 10 10 0 0 1 ${houseSizeData.roofWidth/2+5} 5
              L ${houseSizeData.roofWidth+10} ${houseSizeData.roofHeigth-7}
              A 4 4 0 0 1 ${houseSizeData.roofWidth+10} ${houseSizeData.roofHeigth}
              z
              `} />
        </svg>
      </div>
      {isVisible && <RoomOptionModal roomName={clickedRoom} modalWidth={houseSizeData.houseWidth} modalHeight={houseSizeData.firstFloorHeight} bg={"#FFF"}
                                     topPos={top} leftPos={0} optOut={handleOptOut} close={closeRoomOptions} optOutPossible={optOutPossible}/>}
      <div id={"svg-house-second-floor"} style={{margin: 0, padding: 0, position: "relative", display: "block", width: "100%", height: houseSizeData.firstFloorHeight}} >
        <SecondFloor roomName={"Obergeschoss"}/>
      </div>
      <div id="svg-house-first-floor" style={{margin: 0, padding: 0, position: "relative", display: "block", width: "100%", height: houseSizeData.firstFloorHeight}} >
        <FirstFloor roomName={"Erdgeschoss"} />
        <Kitchen roomName={"Küche"} />
        <div className="grass-div" style={{width: "100%", height: "15px", bottom: "-5px", overflow: "visible", position: "absolute", zIndex: -5}}>
          <svg overflow={"visible"} width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polyline points={lawnPoints()}/>
          </svg>
        </div>
      </div>
      <div id={"svg-house-basement"} style={{margin: 0, padding: 0, position: "relative", display: "block", width: "100%", height: houseSizeData.firstFloorHeight}}>
        <Basement roomName={"Keller"} />
      </div>
      <Notification data={notification} setNotification={setNotification} />
    </div>
  )
}

House.displayName = "House";

export default House;