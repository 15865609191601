import React from "react";
import {Box, Typography} from "@mui/material";

import Hero from "../../Hero";
import Destination from "./Destination.component";
import DestinationDetails from "./DestinationDetails.component";
import map from "../../media/map.png"

interface DestinationInt {
  id: string,
  name: string,
  nights: number,
  travelBy: string,
  days: string
}

interface DestinationDetailsInt {
  id: string,
  name: string,
  unterkunft: string,
  cost: string,
  days: string
}

const TravelPage = () => {

  const destinations: DestinationInt[] = [
    {
      id: "trieste",
      name: "Trieste",
      nights: 2,
      travelBy: "NightJet",
      days: "06.-08."
    },
    {
      id: "pula",
      name: "Pula",
      nights: 2,
      travelBy: "Auto",
      days: "09.-11."
    },
    {
      id: "split",
      name: "Split",
      nights: 2,
      travelBy: "Auto",
      days: "12.-14."
    },
    {
      id: "budapest",
      name: "Budapest",
      nights: 3,
      travelBy: "Zug",
      days: "15.-18."
    },
    {
      id: "munich",
      name: "München",
      nights: 0,
      travelBy: "Zug",
      days: "19."
    },
  ]

  const details: DestinationDetailsInt[] = [
    {
      id: "trieste",
      name: "Trieste",
      unterkunft: "https://www.booking.com/hotel/it/piazzaborsa-charme-apartment.de.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaDuIAQGYAQe4AQfIAQ3YAQHoAQH4AQyIAgGoAgO4AuTiwKwGwAIB0gIkMDk2OWNjMDUtMDIzYy00YjgyLWFhNGYtNWJmZWEwOWJkMGU12AIG4AIB&sid=ec5bc5d917412bebd360fc9e21a7f464&dest_id=8554&dest_type=district&nflt=price%3DEUR-min-190-1&room1=A%2CA%2CA%2CA&group_adults=4&group_children=0&no_rooms=1&checkin=2024-05-07&checkout=2024-05-09&highlighted_blocks=1072561601_379795795_4_0_0&atlas_src=sr_iw_title&ucfs=1",
      cost: "~30€",
      days: "06.-08."
    },
    {
      id: "pula",
      name: "Pula",
      unterkunft: "https://www.booking.com/hotel/hr/apartment-heart-of-old-town.de.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaDuIAQGYAQe4AQfIAQ3YAQHoAQH4AQyIAgGoAgO4AuTiwKwGwAIB0gIkMDk2OWNjMDUtMDIzYy00YjgyLWFhNGYtNWJmZWEwOWJkMGU12AIG4AIB&sid=ec5bc5d917412bebd360fc9e21a7f464&dest_id=-93462&dest_type=city&nflt=di%3D8677&room1=A%2CA%2CA%2CA&group_adults=4&group_children=0&no_rooms=1&checkin=2024-05-10&checkout=2024-05-12&highlighted_blocks=71510802_190334905_4_0_0&atlas_src=sr_iw_title&ucfs=1&activeTab=photosGallery",
      cost: "~23€",
      days: "09.-11."
    },
    {
      id: "split",
      name: "Split",
      unterkunft: "https://www.booking.com/hotel/hr/ibis-split.de.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaDuIAQGYAQe4AQfIAQ3YAQHoAQH4AQyIAgGoAgO4AuTiwKwGwAIB0gIkMDk2OWNjMDUtMDIzYy00YjgyLWFhNGYtNWJmZWEwOWJkMGU12AIG4AIB&sid=ec5bc5d917412bebd360fc9e21a7f464&atlas_src=sr_iw_btn;checkin=2024-05-13;checkout=2024-05-16;dest_id=-96492;dest_type=city;dist=0;group_adults=4;group_children=0;highlighted_blocks=468645501_329634393_4_0_0;no_rooms=1;room1=A%2CA%2CA%2CA;sb_price_type=total;type=total;ucfs=1&",
      cost: "~20€",
      days: "12.-14."
    },
    {
      id: "budapest",
      name: "Budapest",
      unterkunft: "https://www.booking.com/hotel/hu/urban-rooms.de.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaDuIAQGYAQe4AQfIAQ3YAQHoAQH4AQyIAgGoAgO4AuTiwKwGwAIB0gIkMDk2OWNjMDUtMDIzYy00YjgyLWFhNGYtNWJmZWEwOWJkMGU12AIG4AIB&sid=ec5bc5d917412bebd360fc9e21a7f464&dest_id=2279&dest_type=district&nflt=review_score%3D80%3Breview_score%3D90&room1=A%2CA%2CA%2CA&group_adults=4&group_children=0&no_rooms=1&checkin=2024-05-17&checkout=2024-05-20&highlighted_blocks=405020303_340970873_4_0_0&atlas_src=sr_iw_title&ucfs=1#map_closed",
      cost: "~17€",
      days: "15.-18."
    },
  ]

  return (
    <div className={"page-content-root"}>
      <Hero title={"Reiseplanung"} />
      <Box id={"map-image"} sx={{position: "relative", width: "300px", minHeight: 300, border: "solid black 2px", marginBottom: "20px", borderRadius: "10px", objectFit: "contain"}}>
        <img id={"map"} alt={"map"} src={map}/>
      </Box>
      <Box id={"travel-dates"} sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "75%", marginY: "20px"}}>
        <Typography variant={"body1"}>Zeitraum: 06.05.2024 - 20.05.2024</Typography>
        <Typography variant={"body1"}>Optional: 20.05.2024 - 02.06.2024</Typography>
      </Box>
      <Box id={"destinations"} sx={{width: "80%"}}>
        {destinations.map(dest => (
          <Destination {...dest} />
        ))}
      </Box>
      <Box id={"travel-infos"} sx={{width: "80%"}}>
        {details.map(dest => (
          <DestinationDetails {...dest} />
        ))}
      </Box>
    </div>
  )
}

export default TravelPage;