import React from "react";
import {Box} from "@mui/material";

const HouseRectangle = (props: {
  children: React.ReactElement,
  id?: string,
  className?: string,
  top: number,
  left: number,
  rectWidth: number,
  rectHeight: number,
  position: string,
  //visibility: string,
  zIndex: number,
  name: string,
  clickHandler?: (...args: any[]) => void
}): React.ReactElement => {

  const houseSizeData = {
    roofWidth: 300,
    roofHeigth: 120,
    houseWidth: 300,
    firstFloorHeight: 110,
    firstFloorWidth: 190,
    secondFloorHeight: 110,
    kitchenWidth: 110,
    kitchenHeight: 110,
    basementFloorHeight: 110,
    rectRadius: 8
  }
  return (
  <Box
    id={props.id}
    className={props.className}
    data-name={props.name}
    onClick={props.clickHandler}
    sx={{
      margin: 0,
      padding: 0,
      top: props.top?? 0,
      left: props.left?? 0,
      position: props.position === "" ? "relative" : props.position,
      //visibility: props.visibility === "" ? undefined : props.visibility,
      zIndex: props.zIndex?? 0,
      display: "inline-block",
      width: props.rectWidth,
      height: houseSizeData.secondFloorHeight
    }}>
    {props.children}
    <svg overflow={"visible"} width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <rect x={0} y={0} rx={houseSizeData.rectRadius} ry={houseSizeData.rectRadius} width="100%" height="100%" onClick={props.clickHandler}/>
    </svg>
  </Box>
  )}

export default HouseRectangle;