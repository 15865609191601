import React from "react";
import {Button, TextField} from "@mui/material";
import {useFormik} from "formik";

import {signInValidationSchema} from "./signInValidationSchema";
import {signIn} from "./signIn";
import {useNavigate} from "react-router-dom";

interface SignInValues {
  email: string,
  password: string
}
interface Notification {
  open: boolean,
  message: string,
  type: any
}

const SignInForm = (props: {setNotification: (data: Notification) => void}) => {

  const navigate = useNavigate();

  const handleSignIn = (values: SignInValues) => {
    try {
      signIn(values, props.setNotification, navigate);
    } catch (e) {
      console.log("Error in sign in.", e)
    }
  }

  const formik = useFormik({
    initialValues: {email: "", password: ""},
    validationSchema: signInValidationSchema,
    onSubmit: values => handleSignIn(values)
  })

  return (
    <div style={{position: "relative", width: "90%"}}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id={"email"}
          type={"text"}
          label={"E-Mail"}
          placeholder={"peterpan@wunderland.hui"}
          fullWidth
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          {...formik.getFieldProps("email")}
        />
        <TextField
          id={"password"}
          type={"password"}
          label={"Passwort"}
          placeholder={"***"}
          fullWidth
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          {...formik.getFieldProps("password")}
        />
        <Button variant={"contained"} type={"submit"}>Anmelden</Button>
      </form>
    </div>
  )
}

export default SignInForm;