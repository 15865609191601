import {getAuth, sendPasswordResetEmail} from "./";
import {getCurrentUser} from "./firestoreAuth";

const resetPassword = (email: string) => {
  const auth = getAuth();

  const user = getCurrentUser();
  console.log("user:", user);

  sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("successfully sent password-reset-mail.")
    })
    .catch(e => {console.log(e.message)})
}

export default resetPassword;