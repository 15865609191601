import {onAuthStateChanged, getAuth} from "../firebase";

const addAuthListener = (callback: any) => {
  const auth = getAuth();
  const onChange = (user: any) => {
    // Is executed if auth state changes
    // "user: any" is provided by onAuthStateChanged
    // console.log("addAuthListener\n", "firebase user:", user)
    if (user) {
      callback(user);
    } else {
      callback(null);
    }
  }
  return onAuthStateChanged(auth, onChange)
}

export {addAuthListener}