import React from "react";
import {Navigate} from "react-router-dom";

interface _ProtectedRoute {
  isAuthed: boolean,
  isLoading: boolean,
  children: React.ReactElement
}

const ProtectedRoute = ({isAuthed, isLoading, children}: _ProtectedRoute): React.ReactElement => {
  if (isLoading) {
    return (
      <div>
        <h2>Loading...</h2>
      </div>
    )
  } else if (!isAuthed) {
    return <Navigate to={"/signin"} />
  } else {
    return children
  }
}

export default ProtectedRoute;