import {getAuth, signInWithEmailAndPassword} from "./";
import {User} from "firebase/auth";

// interface UserRoles {
//   isLoading: boolean,
//   user: {} | null,
//   roles: any //Promise<IdTokenResult> | undefined
// }

const signIn = () => {
  try {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, "", "")
      .catch(e => console.log(e))
  } catch (e) {
    console.log(e)
  }
}

const getCurrentUser = () => {
  const auth = getAuth();
  const user: User | null = auth.currentUser;
  if (!user) {return null}
  return user
}

const getUserRole = () => {
  const auth = getAuth();

  return auth.currentUser?.getIdTokenResult()
    .then((idTokenResult) => {
      return idTokenResult.claims.roles;
    })
}

export {signIn, getCurrentUser, getUserRole};