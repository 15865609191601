import {getAuth, createUserWithEmailAndPassword} from "./index"

interface SignUpData {
  name: string,
  email: string,
  password: string
}

const signUp = (signUpData: SignUpData): Promise<any> => {
  const auth = getAuth();
  return createUserWithEmailAndPassword(auth, signUpData.email, signUpData.password);
}

export default signUp;