import {addDoc, collection, firestore, getDocs, onSnapshot, orderBy, query, Timestamp, where, getAuth} from "./"

interface FirestoreFinancialResponse {
  _loading: boolean,
  _debtsEntries: DebtEntry[],
  _creditsEntries: CreditEntry[]
}

interface DebtEntry {
  docId: string,
  amount: string,
  objective: string,
  creditor: string,
  confirmed: boolean,
  cleared: string,
  date: string
}



interface CreditEntry {
  docId: string,
  amount: string,
  objective: string,
  debtor: string,
  confirmed: boolean,
  cleared: string,
  date: string
}

const getAllFinancialEntries = async (): Promise<FirestoreFinancialResponse> => {
  const debtsQuery = query(collection(firestore, `Financials/2021/m1`), where("debtor", "==", "Marco"), orderBy("date", "desc"))
  const creditsQuery = query(collection(firestore, `Financials/2021/m1`), where("creditor", "==", "Marco"), orderBy("date", "desc"))
  const debtsDocuments = await getDocs(debtsQuery);
  const creditsDocuments = await getDocs(creditsQuery);
  const debtsEntries = debtsDocuments.docs.map((doc) => {
    const docId = doc.id;
    const {amount, objective, creditor, confirmed, cleared, date} = doc.data();
    return {docId: docId, amount: amount, objective: objective, creditor: creditor, confirmed: confirmed, cleared: cleared, date: date}
  })
  const creditorEntries = creditsDocuments.docs.map((doc) => {
    const docId = doc.id;
    const {amount, objective, debtor, confirmed, cleared, date} = doc.data();
    return {docId: docId, amount: amount, objective: objective, debtor: debtor, confirmed: confirmed, cleared: cleared, date: date}
  })
  return {_debtsEntries: debtsEntries, _creditsEntries: creditorEntries, _loading: false};
}

// const getAllDebtsEntries = (setDebtsEntries: any): () => void => {
//   const debtsQuery = query(collection(firestore, `Financials/2021/m1`), where("debtor", "==", "Marco"), orderBy("date", "desc"));
//   return onSnapshot(debtsQuery, (col) => {
//     const data: any = col.docs.map(doc => ({
//       ...doc.data(),
//       docId: doc.id
//     }))
//     const openDebts = data.filter((el: { cleared: string }) => el.cleared !== "Yes")
//     setDebtsEntries(openDebts)
//   });
// }

const getCountOfEntries = async (): Promise<number> => {
  const allEntries = await getDocs(query(collection(firestore, `Financials/2021/m1`)));
  return allEntries.size;
}

const fbCreateFinancialEntry = async (data: any): Promise<{docId: string, data: {}}> => {
  const auth = getAuth();
  const uid = auth.currentUser?.uid;
  // const localeDateString = new Date().toLocaleDateString("de-DE", {weekday: "long", year: "numeric", month: "long", day: "numeric"});
  const dateNow = new Date();
  // const dateNow2 = Date.parse(localeDateString);
  data["date"] = Timestamp.fromDate(dateNow);
  // console.log("date string:", localeDateString);
  console.log("datenow:", dateNow);
  // console.log("datenow2:", dateNow2);
  console.log("data:", data["date"]);
  const docId =  await addDoc(collection(firestore, `Financials/2021/m1`), data);
  return {docId: docId.id, data}
}

export {getAllFinancialEntries, getCountOfEntries, fbCreateFinancialEntry}