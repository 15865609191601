import React from "react";
import {Button, Menu, MenuItem, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {useFbAuth} from "../../adaptors/auth/useAuth";

import {firebaseSignOut} from "../../adaptors/firebase/firebaseSignOut";

import "./topMenu.css"

const TopMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {user} = useFbAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    firebaseSignOut()
      .then(_ => console.log("signed out"))
      .catch(e => console.log("Error during signing out.", e.message))
  }
  return (
    <>
      <Button
        id={"basic-menu"}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={regular('bars')} size={"2x"} />
      </Button>
      <Menu
        id={"basic-menu"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Link to={"/"} style={{textDecoration: "none"}}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FontAwesomeIcon icon={thin('house')} size={"lg"} />
              <ListItemText sx={{marginLeft: "10px"}}>Home</ListItemText>
            </ListItemIcon>
          </MenuItem>
        </Link>
        <Link to={"/cleaningplan"} style={{textDecoration: "none"}}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FontAwesomeIcon icon={thin('broom-wide')} size={"lg"} />
              <ListItemText sx={{marginLeft: "10px"}}>Putzplan</ListItemText>
            </ListItemIcon>
          </MenuItem>
        </Link>
        <Link to={"/financials"} style={{textDecoration: "none"}}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FontAwesomeIcon icon={thin('money-bill-transfer')} size={"lg"} />
              <ListItemText sx={{marginLeft: "10px"}}>Finanzen</ListItemText>
            </ListItemIcon>
          </MenuItem>
        </Link>
        <Link to={"/calendar"} style={{textDecoration: "none"}}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FontAwesomeIcon icon={thin('calendar')} size={"lg"} />
              <ListItemText sx={{marginLeft: "10px"}}>Kalender</ListItemText>
            </ListItemIcon>
          </MenuItem>
        </Link>
        <Link to={"/statistics"} style={{textDecoration: "none"}}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FontAwesomeIcon icon={thin('chart-simple')} size={"lg"} />
              <ListItemText sx={{marginLeft: "10px"}}>Statistiken</ListItemText>
            </ListItemIcon>
          </MenuItem>
        </Link>
        {user &&
          <Link to={"/account"} style={{textDecoration: "none"}}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <FontAwesomeIcon icon={thin('user')} size={"lg"} />
                <ListItemText sx={{marginLeft: "10px"}}>Account</ListItemText>
              </ListItemIcon>
            </MenuItem>
          </Link>
        }
        {user &&
          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <FontAwesomeIcon icon={thin('arrow-right-from-bracket')} size={"lg"} />
              <ListItemText sx={{marginLeft: "10px"}}>Abmelden</ListItemText>
            </ListItemIcon>
          </MenuItem>
        }
      </Menu>
    </>
  )
}

export default TopMenu;