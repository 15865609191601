import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';
import Homepage from "./pages/home_page/homepage";
import SignInPage from "./pages/sign_in/SignInPage";
import UserPage from "./pages/user_page/UserPage";
import CleaningPlan from "./pages/cleaning_plan_page/CleaningPlan";
import Financials from "./pages/financial_page/Financials";
import SignUpPage from "./pages/sign_up/SignUpPage";
import CalendarPage from "./pages/calendar_page/CalendarPage";
import StatisticsPage from "./pages/statistics_page/StatisticsPage";
import TravelPage from "./pages/travel_page/TravelPage";
import Header from "./Header";
import ProtectedRoute from "./ProtectedRoute";
import {useFbAuth} from "./adaptors/auth/useAuth";

function App() {
  const {isLoading, user} = useFbAuth();
  if (user?.email) {
    localStorage.setItem("fb_user_email", user.email);
  }
  return (
    <Router>
      <Header />
      <Routes>
        <Route path={'/'} element={
          <Homepage />
        } />
        <Route path={"/account"} element={
          <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
            <UserPage />
          </ProtectedRoute>
        } />
        <Route path={'/cleaningplan'} element={
          <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
            <CleaningPlan />
          </ProtectedRoute>
        } />
        <Route path={'/financials'} element={
          <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
            <Financials />
          </ProtectedRoute>
        } />
        <Route path={"/calendar"} element={
          <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
            <CalendarPage />
          </ProtectedRoute>
        } />
        <Route path={"/statistics"} element={
          <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
            <StatisticsPage />
          </ProtectedRoute>
        } />
        <Route path={"/travel"} element={
          <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
            <TravelPage />
          </ProtectedRoute>
        } />
        <Route path={'/signin'} element={
          <SignInPage />
        } />
        <Route path={"/signup"} element={
          <SignUpPage />
        } />
      </Routes>
      {/*<BottomMenuButton />*/}
    </Router>
  );
}

export default App;
