// the following line is for typescript 4.x
import type {} from '@mui/x-date-pickers/themeAugmentation';
import React, {useState, useEffect} from "react";
import {
  Box,
  Button,
  createTheme,
  ThemeProvider,
  ListItemButton,
  ListItemText,
  Paper,
  List,
  ListItem,
  Badge,
  LinearProgress
} from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DatePicker } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { thin, regular, solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import Hero from "../../Hero";
import {getAllCalendars, getHighlightedDays, addHighlightedDay} from "../../adaptors/firebase/firestoreCalendar";
import "./calendarpage.css";

dayjs.extend(utc);
dayjs.extend(timezone);

interface _getHDays {
  month?: string,
  year?: string
  calendarId?: string
}

let theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "transparent",
            color: "#000",
            fontWeight: "bold"
          },
          "&.Mui-selected:focus": {
            backgroundColor: "transparent",
          }
        },
      }
    }
  }
})

theme = createTheme(theme, {
  components: {
    // Following for adjusting the width and height
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up("md")]: {
            maxHeight: "574px",
            width: "100%"
          }
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          [theme.breakpoints.up("md")]: {
            justifyContent: "space-between"
          }
        },
        weekContainer: {
          [theme.breakpoints.up("md")]: {
            padding: "20px 0",
            justifyContent: "space-between"
          }
        },
        slideTransition: {
          [theme.breakpoints.up("md")]: {
            minHeight: "480px"
          }
        }
      }
    },
  }
})


const CleanerDays = (props: PickersDayProps<Dayjs> & {highlightedDays?: number[], calendars?: {id: string, nameGer: string}[], selectedCalendar?: number}) => {
  const {highlightedDays= [], calendars = [], selectedCalendar = 1, day, outsideCurrentMonth, ...other} = props;

  const isSelected = !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

  let ficon: any = <></>
  if (calendars.length > 0) {
    switch (calendars[selectedCalendar].id) {
      case "cleaner":
        ficon = <FontAwesomeIcon icon={solid("broom")} style={{color: "#000"}} size={"1x"} />
        break
      case "paperWaste":
        ficon = <FontAwesomeIcon icon={solid("trash")} style={{color: "#005eff",}} size={"1x"} />
        break
      case "residualWaste":
        ficon = <FontAwesomeIcon icon={solid("trash")} style={{color: "#000",}} size={"1x"} />
        break
      case "party":
        ficon = <FontAwesomeIcon icon={solid("party-horn")} style={{color: "#ffaa50",}} size={"1x"} />
        break
      default:
        ficon = <FontAwesomeIcon icon={solid("apple-whole")} style={{color: "#000",}} size={"1x"} />
    }
  }

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? ficon : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  )
}

const CalendarPage = (): React.ReactElement => {

  const initializeDate: Dayjs = dayjs();

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [calendars, setCalendars] = useState<any[]>([])
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<number>(2);
  const [calendarEditTime, setCalendarEditTime] = useState<number>(100);

  const getHDays = (params: _getHDays) => {
    const {calendarId, month, year} = params;
    const c: string = calendarId ? calendarId : calendars[selectedCalendar].id;
    const m: string = month ? month : (selectedDate.month()+1).toString();
    const y: string = year ? year : selectedDate.year().toString();
    console.log(calendars)
    console.log(c, m, y)
    getHighlightedDays({id: c, month: m, year: y})
      .then(resp => {
        if (resp.length > 0) {
          setHighlightedDays(resp);
        } else {
          console.log("no data available");
        }
      })
  }

  useEffect(() => {
    setSelectedDate(initializeDate);
    const m = (initializeDate.month()+1).toString();
    const y = initializeDate.year().toString();
    getAllCalendars()
      .then(resp => {
        if (resp.length > 0) {
          resp.unshift({id: "all", nameGer: "Alle Termine"});
          setCalendars(resp);
        } else {
          console.log("No calendars found")
        }
      })
  }, []);

  useEffect(() => {
    if (calendars.length > 0) {
      getHDays({});
    }
  }, [selectedMonth, calendars, selectedCalendar]);


  const handleMonthChange = (value: Dayjs) => {
    setHighlightedDays([]);
    setSelectedDate(value);
    setSelectedMonth((value.month()+1).toString());
  }

  const handleCalendarChange = (cal: any, index: number) => {
    if (index !== selectedCalendar) {
      setHighlightedDays([]);
      setSelectedCalendar(index)
    }
  }


  const handleDateChange = (value: Dayjs|null) => {
    if (calendarEditTime < 100 && value) {
      setSelectedDate(value);
      if (selectedCalendar !== 0) {
        const m = (value.month()+1).toString();
        const y = value.year().toString();
        const d = value.date();
        if (highlightedDays.includes(d)) {
          // remove
          const newHighlightedDays = highlightedDays.filter(val => val !== d);
          setHighlightedDays(newHighlightedDays);
          addHighlightedDay(calendars[selectedCalendar].id, m, y, d, "remove")
            .then(resp => console.log(resp))
        } else {
          const newHighlightedDays = highlightedDays;
          newHighlightedDays.push(d);
          setHighlightedDays(newHighlightedDays);
          addHighlightedDay(calendars[selectedCalendar].id, m, y, d, "add")
            .then(resp => console.log(resp))
        }
      } else {
        console.log("deactivated calendar");
      }
    } else {
      console.log("Please activate edit state")
    }
  }

  //@ts-ignore
  const handleEditButtonClick = ({target}) => {
    console.log(target)
    target.setAttribute("disabled", "");
    const interval = setInterval(() => {
      setCalendarEditTime((oldProgress) => {
        if (oldProgress === 0) {
          clearTimeout(interval);
          return 100;
        }
        const diff: number = 1;
        return Math.max(oldProgress - diff, 0);
      });
    }, 300);
  }

  return (
    <div className={"page-content-root"}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{
          calendarWeekNumberHeaderText: '#',
          calendarWeekNumberText: (weekNumber) => `${weekNumber}.`,
        }}
        adapterLocale="de"
      >
        <Hero title={"Kalender"} />
        <Button
          onClick={handleEditButtonClick}
          disabled={calendarEditTime < 100}
        >Bearbeiten</Button>
        <Box sx={{width: "200px", height: "4px"}}>
          <LinearProgress variant={"determinate"} value={calendarEditTime} />
        </Box>
        <Box className={"calendar-wrapper"}>
          <Box className={"calendar"}>
            <ThemeProvider theme={theme}>
              <DateCalendar
                loading={false}
                views={["day"]}
                renderLoading={() => (<DayCalendarSkeleton/>)}
                onMonthChange={handleMonthChange}
                onChange={handleDateChange}
                slots={{
                  day: CleanerDays
                }}
                slotProps={{
                  day: {highlightedDays, calendars, selectedCalendar} as any
                }}
                displayWeekNumber
                timezone={"Europe/Berlin"}
              />
            </ThemeProvider>
            <Box>
              <Paper>
                <List>
                  <ListItem>
                    test
                  </ListItem>
                </List>
              </Paper>
            </Box>
          </Box>
        </Box>
        <Box>
          <Paper>
            <List sx={{width: "9rem"}}>
              {calendars.map((cal: any, index: number) => (
                <ListItem disablePadding >
                  <ListItemButton
                    selected={index === selectedCalendar}
                    onClick={() => {
                      handleCalendarChange(cal.id, index)
                    }}
                    disabled={cal.id === "all"}
                  >
                    <ListItemText>
                      {cal.nameGer}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                ))}
            </List>
          </Paper>
        </Box>
      </LocalizationProvider>
    </div>
  )
}

export default CalendarPage;