import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Box, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import TopMenu from "./modules/top_menu/TopMenu";
import {firebaseSignOut} from "./adaptors/firebase/firebaseSignOut";

import './Header.css'
import wgLogo from './media/WG_Muc_Logo.png';

export default function Header() {
    // const desktop = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    const handleSignOut = () => {
      firebaseSignOut()
        .then((resp) => {
          console.log("sign out response:", resp);
          navigate("/");
        })
        .catch(e => console.log(e))
    }
    
    return(
      <Box
        sx={{
            width: '100%',
            height: '10%',
            p: 2,
            // mb: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflow: 'hidden',
            backgroundColor: "#defff0"
        }}
      >
          {/*{!desktop ?*/}
          {/*  <img className={'bg-page-root'} src={background} alt={'background'}/> :*/}
          {/*  <img className={'bg-page-root'} src={backgroundDesktop} alt={'background-desktop'}/>*/}
          {/*}*/}
        <Link to={'/'}>
          <div style={{width: '50px', height: '50px'}}>
            <img src={wgLogo} alt={'wg logo'} style={{width: '100%', objectFit: 'contain'}}/>
          </div>
        </Link>
        <TopMenu />
        {/*<div>*/}
        {/*  <Button onClick={handleSignOut}><LogoutIcon /></Button>*/}
        {/*</div>*/}
      </Box>
    )
}

Header.propTypes = {
    
}