import {useEffect, useState} from "react";
import {getAllRoomResponsibilities, getAllRooms} from "../../adaptors/firebase/firestoreCleaningPlan";
import update from "immutability-helper";

interface GetAllRooms {
    active: boolean,
    id: string,
    name: string,
    orderId: number,
    v2Name: string,
    v2id: string,
    version: string
    optOutPossible: boolean
}

interface GetRoomResponsibility {
    id: string,
    helpsOut: boolean,
    room: string
}

interface State {
    loading: boolean,
    roomsData: GetAllRooms[],
    responsibilityData: GetRoomResponsibility[]
}

export default function useCleaningPlan(): State {
    const [state, setState] = useState<State>({
        loading: true,
        roomsData: [],
        responsibilityData: []
    })

    useEffect(() => {
        const allRooms: Promise<GetAllRooms[]> = getAllRooms();
        const allRoomResponsiblities: Promise<GetRoomResponsibility[]> = getAllRoomResponsibilities(["first-floor", "second-floor", "kitchen", "basement"]);
        console.log(allRoomResponsiblities);
        Promise.all([allRooms, allRoomResponsiblities]).then((resp) => {
            setState(state => update(state, {
                loading: {$set: false},
                roomsData: {$set: resp[0]},
                responsibilityData: {$set: resp[1]}
            }))
        });
    }, [])

    return state
}