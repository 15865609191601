import React from "react";

interface _ProtectedSection {
  isLoading: boolean,
  isAuthed: boolean,
  userRole: number,
  authRole: number,
  children: React.ReactElement
}

const ProtectedSection = ({isLoading, isAuthed, userRole, authRole, children}: _ProtectedSection) => {
  /**
   * Das übergebene ReactElement wird nur zurückgegeben, wenn die übergebene geforderte Rolle in dem firebase
   * role-Array vom User enthalten ist.
   * Zunächst ist alles sichtbar. Nur wenn etwas für "User" oder "Admin" geschützt ist, kann ein registrierter User
   * (initial als "guest") das nicht sehen.
   */

  if (isLoading) {
    return null
  } else if (isAuthed) {
    if (userRole >= authRole) {
      return children
    } else {
      return null
    }
  } else {
    return null
  }
}

export default ProtectedSection;