import {signInWithEmailAndPassword, getAuth} from "./";

interface SignInObject {
  email: string,
  password: string
}
const fbSignIn = async (signInObj: SignInObject) => {
  const auth = getAuth();
  return await signInWithEmailAndPassword(auth, signInObj.email, signInObj.password)
}

export {fbSignIn}