import Hero from "../../Hero";
import {Typography, useMediaQuery} from "@mui/material";

import "./homepage.css"

const Homepage = () => {

  const desktop = useMediaQuery('(min-width:600px)');

  return (
    <div className="page-content-root">
      <Hero title="WG München" />
      <div className={"main-content"} style={{width: desktop ? "760px" : "90%"}}>
        <div style={{width: "100%", height: "7rem"}}>
          <Typography variant="h5">Hey, schön dass du da bist</Typography>
        </div>
      </div>
    </div>
  )
}

export default Homepage;