import background from "./media/bg.png";
import backgroundDesktop from "./media/bg_desktop.png";
import {Box, Typography, useMediaQuery} from "@mui/material";
import React from "react";

const Hero = (props: { title: string }): JSX.Element => {
  const desktop = useMediaQuery('(min-width:600px)');

  return (
    <Box mb={"70px"}>
      {!desktop ?
        <img className={'hero-bg'} src={background} alt={'background'}/> :
        <img className={'hero-bg'} src={backgroundDesktop} alt={'background-desktop'}/>
      }
      <Typography variant={"h4"}>{props.title}</Typography>
    </Box>
  )
}

export default Hero;