import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDoc, getDocs, doc, query, orderBy, where, addDoc, setDoc, updateDoc, deleteDoc, increment, arrayUnion, arrayRemove, limit, Timestamp, connectFirestoreEmulator, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, connectAuthEmulator, sendPasswordResetEmail } from "firebase/auth";

let firebaseConfig;

// console.log("hostname:", window.location.hostname)
if (window.location.hostname === "localhost") {
  console.log("using local firebase config")
  firebaseConfig = {
    apiKey: "AIzaSyCOK_drNo9uXaMw4otK8bOo2ngirP3C-rg",
    authDomain: "clean-wg-muc.firebaseapp.com",
    databaseURL: "http://localhost:9000?ns=emulatorui",
    projectId: "clean-wg-muc",
    storageBucket: "clean-wg-muc.appspot.com",
    messagingSenderId: "37731695784",
    appId: "1:37731695784:web:b7574942af7351ace8969d",
    measurementId: "G-W2MFJ2RX3Z"
  }
} else {
  console.log("using production firebase config")
  firebaseConfig = {
    apiKey: "AIzaSyCOK_drNo9uXaMw4otK8bOo2ngirP3C-rg",
    authDomain: "clean-wg-muc.firebaseapp.com",
    databaseURL: "https://clean-wg-muc.firebaseio.com",
    projectId: "clean-wg-muc",
    storageBucket: "clean-wg-muc.appspot.com",
    messagingSenderId: "37731695784",
    appId: "1:37731695784:web:b7574942af7351ace8969d",
    measurementId: "G-W2MFJ2RX3Z"
  };
}


const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}

export {firestore, getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, collection, getDoc, getDocs, deleteDoc, query, orderBy, where, limit, doc, addDoc, setDoc, updateDoc, increment, arrayUnion, arrayRemove, Timestamp, onSnapshot, sendPasswordResetEmail}
