import React from "react";
import {Box, Typography, Paper, Button} from "@mui/material"

interface SettingsPanelInterface {
  id: string,
  settingsLabel: string,
  settingsValue: string,
  buttonLabel: string,
  handleButtonClick: any
}
const SettingsPanel = (props: SettingsPanelInterface): React.ReactElement => {

  return (
    <Box key={props.id} className={"settings-panel"}>
      <Paper key={props.id}>
        <Typography id={"label"} variant={"body1"}>{props.settingsLabel}</Typography>
        <Typography id={"value"} variant={"body1"}>{props.settingsValue}</Typography>
        <Button id={"button"} variant={"contained"} disabled={!props.handleButtonClick} onClick={props.handleButtonClick}>{props.buttonLabel}</Button>
      </Paper>
    </Box>
  )
}

export default SettingsPanel;