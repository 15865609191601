import React, {useState} from "react";
import {Box, Button, Alert, Snackbar, Modal, Typography, TextField} from "@mui/material";
import Hero from "../../Hero";
import SignInForm from "./SignInForm";
import {Link} from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import resetPassword from "../../adaptors/firebase/firebaseResetPassword";

import resetPasswordValidationSchema from "./resetPasswordValidationSchema";
import {useFormik} from "formik";

interface Notification {
  open: boolean,
  message: string,
  type: any
}

const SignInPage = () => {
  // const pageName = "sign-in";
  const [notification, setNotification] = useState<Notification>({open: false, message: "", type: "success"})
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {email: ""},
    validationSchema: resetPasswordValidationSchema,
    onSubmit: email => handleForgotPassword(email)
  })

  const handleForgotPassword = (values: {email: string}): void => {
    resetPassword(values.email)
    setPasswordResetModalOpen(false);
    setNotification({open: true, message: "Passwort wurde verschickt", type: "success"});
  }

  const closeModal = (): void => {
    setPasswordResetModalOpen(false);
  }

  const openModal = (): void => {
    setPasswordResetModalOpen(true);
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({
      open: false,
      message: "",
      type: "success"
    });
  };

  return (
    <div className={"page-content-root"}>
      <Hero title="Anmelden" />
      <SignInForm setNotification={setNotification} />
      <Box>
        <Button variant={"outlined"} onClick={openModal}>Passwort vergessen</Button>
        <Link to={"/signup"}><Button variant={"outlined"}>Registrieren</Button></Link>
      </Box>
      <Modal open={passwordResetModalOpen} onClose={closeModal}>
        <Box style={{position: "absolute", width: 300, height: 300, top: "50%", left: "50%", transform: 'translate(-50%, -50%)', border: '2px solid #000', boxShadow: "24", padding: 4, backgroundColor: "white"}}>
          <Typography variant={"h5"}>E-Mail</Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id={"email"}
              label={"E-Mail"}
              placeholder={"peterpan@wunder.hui"}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={"Bitte eine E-Mail angeben"}
              {...formik.getFieldProps("email")}
            ></TextField>
            <Button variant={"contained"} type={"submit"}>Passwort anfordern</Button>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert variant={"filled"} icon={<CheckIcon fontSize="inherit" />} severity={notification.type} onClose={handleSnackbarClose}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default SignInPage;