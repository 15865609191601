import React, {useState, useEffect} from "react";
import {Box, Typography, ToggleButton, ToggleButtonGroup, Snackbar, Alert} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

import Hero from "../../Hero";
import FinancialEntry from "./FinancialEntry";
import FinancialForm from "./FinancialForm";
import getAllDebtsEntries from "../../adaptors/firebase/getAllDebtsEntries";
import getAllCreditsEntries from "../../adaptors/firebase/getAllCreditsEntries";
import uidToUsername from "../../adaptors/firebase/uidToUsername";
import "./financials.css"
import ProtectedSection from "../../ProtectedSection";
import {useFbAuth} from "../../adaptors/auth/useAuth";

interface FinancialData {
  amount: string,
  cleared: string,
  payed: boolean,
  confirmed: boolean,
  creditor: string,
  creditorId: string,
  date: any
  debtor: string,
  id: string
  docId: string,
  invoiced: string,
  objective: string,
}

interface Notification {
  open: boolean,
  message: string,
  type: any
}

const Financials = (): React.ReactElement => {
  const {isLoading, user, role} = useFbAuth();

  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<string | null>("debts");
  const [error, setError] = useState(false);
  const [notification, setNotification] = useState<Notification>({
    open: false,
    message: "",
    type: "success"
  });
  const [openDebtsEntries, setOpenDebtsEntries] = useState<FinancialData[]>([]);
  const [openCreditsEntries, setOpenCreditsEntries] = useState<FinancialData[]>([]);
  const [username, setUsername] = useState<string>("");

  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>, category: string | null,): void => {
    setCategory(category);
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({
      open: false,
      message: "",
      type: "success"
    });
  };

  useEffect(() => {
    try {
      uidToUsername(setUsername)
        .then(() => null)
        .catch(e => console.log(e))
      const debtsUnsub = getAllDebtsEntries(username, setOpenDebtsEntries);
      const creditsUnsub = getAllCreditsEntries(username, setOpenCreditsEntries);
      setLoading(false);
      return () => {
        console.log("triggering unsub")
        debtsUnsub();
        creditsUnsub();
      }
    } catch (e: any) {
      setError(e.message);
    }
  }, [username])

  if (!loading && !error) {
    return (
      <div className={"page-content-root"}>
        <Hero title={"Finanzen"} />
        <ProtectedSection isLoading={isLoading} isAuthed={!!user} userRole={role} authRole={5}>
          <>
            <FinancialForm setNotificationOpen={setNotification} />
            <ToggleButtonGroup
              value={category}
              exclusive
              onChange={handleCategoryClick}
            >
              <ToggleButton value={"debts"}>Schulden</ToggleButton>
              <ToggleButton value={"credits"}>Einnahmen</ToggleButton>
            </ToggleButtonGroup>
            {category === "debts" &&
              <Box id={"debt-entries-div"} sx={{position: "relative", width: "90%"}}>
              <Typography variant={"h5"}>Deine offenen Schulden</Typography>
                {openDebtsEntries.length > 0 && openDebtsEntries.map((debtEntry) => (
                  <FinancialEntry key={debtEntry.docId} financialData={debtEntry} setNotificationOpen={setNotification} type={"debt"}/>
                ))}
                {openDebtsEntries.length === 0 &&
                  <Box>
                    <Typography variant={"h6"} sx={{color: "grey"}}>Keine offenen Einträge</Typography>
                  </Box>}
            </Box>
            }
            {category === "credits" &&
              <Box id={"credit-entries-div"} sx={{position: "relative", width: "90%"}}>
              <Typography variant={"h5"}>Deine offenen Forderungen</Typography>
                {openCreditsEntries.length > 0 && openCreditsEntries.map((creditEntry) => (
                  <FinancialEntry key={creditEntry.docId} financialData={creditEntry} setNotificationOpen={setNotification} type={"credit"}/>
                ))}
                {openCreditsEntries.length === 0 &&
                  <Box>
                    <Typography variant={"h6"} sx={{color: "grey"}}>Keine offenen Einträge</Typography>
                  </Box>}
            </Box>
            }
            <Snackbar
              open={notification.open}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert variant={"filled"} icon={<CheckIcon fontSize="inherit" />} severity={notification.type} onClose={handleSnackbarClose}>
                {notification.message}
              </Alert>
            </Snackbar>
          </>
        </ProtectedSection>
      </div>
    )
  } else if (!error) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    )
  } else {
    return (
      <div>
        <h1>Error 404</h1>
      </div>
    )
  }
}

export default Financials;