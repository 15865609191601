import React from "react";
import {Box, Paper, Button, Typography, Link} from "@mui/material";

import "./destination.component.css";

interface DestinationDetailsInt {
  id: string,
  name: string,
  unterkunft: string,
  cost: string,
  days: string
}

const DestinationDetails = (props: DestinationDetailsInt) => {
  return (
    <Box key={props.id} className={"destination-details-box"}>
      {props.id !== "budapest" && <Box className={"connection-line"}></Box>}
      <Paper>
        <Typography variant={"body1"} className={"destination-name"}>{props.name}</Typography>
        <Typography variant={"body1"} className={"unterkunft"}>Unterkunft: <Link href={props.unterkunft}>Link</Link></Typography>
        <Typography variant={"body1"} className={"cost"}>Kosten p.P. pro Nacht: {props.cost}</Typography>
        <Typography variant={"body1"} className={"days"}>Tage: {props.days}</Typography>
      </Paper>
    </Box>
  )
}

export default DestinationDetails;