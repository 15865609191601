import {firestore, query, collection, getDocs} from "./index"

const firebaseGetAllUsernames = async (): Promise<string[]> => {
  const gquery = query(collection(firestore, "/Users"));
  const userDocs = await getDocs(gquery);
  return userDocs.docs.map((userDoc) => (
    userDoc.data().username
  ))
}

export default firebaseGetAllUsernames;