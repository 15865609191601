import React from "react";
import {TextField, Paper, Button, Modal} from "@mui/material";
import {useFormik} from "formik";
import emailValidationSchema from "./emailValidationSchema";
import {updateEmailAddress} from "../../adaptors/firebase/firebaseUserPage";

interface UpdateEmail {
  email: string
}

interface Input {
  id: string,
  type: string,
  label: string,
  placeholder: string,
  fullwidth: boolean,
  error: boolean,
  helperText: string
}

interface _EmailForm {
  modalOpen: boolean,
  handleModalClose: any,
  setNotificationData: any
}

const EmailForm = (props: _EmailForm): React.ReactElement => {

  const handleSubmit = (values: UpdateEmail) => {
    updateEmailAddress(values.email, props.setNotificationData)
      .then(_ => {
        props.handleModalClose(true);
      })
  }

  const input: Input = {
    id: "email",
    type: "text",
    label: "E-Mail",
    placeholder: "arielle@atlantis.meer",
    fullwidth: true,
    error: false,
    helperText: ""
  }

  const formik = useFormik({
    initialValues: {email: ""},
    validationSchema: emailValidationSchema,
    onSubmit: values => handleSubmit(values)
  })


  return (
    <Modal open={props.modalOpen} onClose={props.handleModalClose}>
      <Paper sx={{position: "absolute", width: "200px", padding: "10px", top: "30%", left: "50%", transform: "translate(-50%, -50%)"}}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id={input.id}
            type={input.type}
            label={input.label}
            placeholder={input.placeholder}
            fullWidth={input.fullwidth}
            // @ts-ignore
            error={formik.touched[input.id] && Boolean(formik.errors[input.id])}
            // @ts-ignore
            helperText={formik.touched[input.id] && formik.errors[input.id]}
            {...formik.getFieldProps(input.id)}
          />
          <Button variant={"contained"} type={"submit"}>Absenden</Button>
        </form>
      </Paper>
    </Modal>
  )
}

export default EmailForm;