import React, {useState, useEffect} from "react";
import {TableContainer, Table, TableHead, TableBody, TableCell, TableRow} from "@mui/material";

import {getStuff} from "../../adaptors/firebase/firestoreStatistics";

const CleaningPlanStatistics = () => {

  const [roomCounts, setRoomCounts] = useState<{user: string, count: number, room: string}[][]>([]);

  useEffect(() => {
    getStuff()
      .then(resp => {
        // console.log(resp)
        //@ts-ignore
        setRoomCounts(resp)

    })
  }, []);

  const users = ["katrin", "quentin", "pascal", "woife", "marco"];

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Raum</TableCell>
              <TableCell>Ka</TableCell>
              <TableCell>Qu</TableCell>
              <TableCell>Pa</TableCell>
              <TableCell>Wo</TableCell>
              <TableCell>Ma</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              roomCounts.map((roomObjArr, i) => {
                return (
                  <TableRow>
                    <TableCell>
                      {roomObjArr[0].room}
                    </TableCell>
                    {users.map((user, j) => {
                      const index = roomObjArr.findIndex(el => el.user === user);
                      return (
                        <>
                          <TableCell>
                            {index >= 0 ? roomObjArr[index].count : "-"}
                          </TableCell>
                        </>
                      )
                    })}
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CleaningPlanStatistics;