import React, {useEffect, useState} from 'react';

import Hero from "../../Hero";
import useCleaningPlan from "./useCleaningPlan";
import "./cleaningPlan.css";
import House from "./House";

interface HouseSizeData {
  roofWidth: number,
  roofHeigth: number,
  houseWidth: number,
  firstFloorHeight: number,
  firstFloorWidth: number,
  secondFloorHeight: number,
  kitchenWidth: number,
  kitchenHeight: number,
  basementFloorHeight: number,
  rectRadius: number
}


export default function CleaningPlan(): React.ReactElement {

  const houseSizeData: HouseSizeData = {
    roofWidth: 300,
    roofHeigth: 120,
    houseWidth: 300,
    firstFloorHeight: 110,
    firstFloorWidth: 190,
    secondFloorHeight: 110,
    kitchenWidth: 110,
    kitchenHeight: 110,
    basementFloorHeight: 110,
    rectRadius: 8
  }

  const {loading, roomsData, responsibilityData} = useCleaningPlan();
  const [load, setLoad] = useState(true);
  const [respData, setRespData] = useState({});


  useEffect(() => {
    setLoad(loading);
    setRespData(responsibilityData);
  }, [loading, responsibilityData])

  if (!load) {
    return (
      <div className={'page-content-root'}>
        <Hero title="Putzplan" />
        <House houseSizeData={houseSizeData} respData={respData} setRespData={setRespData} roomsData={roomsData}/>
      </div>
    )
  } else {
    return (
      <div className={'page-content-root'}>Loading..</div>
    )
  }
}
