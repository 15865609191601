import React from "react";
import {Box, Paper, Typography, Button} from "@mui/material";

import setFinEntryAsPayed from "../../adaptors/firebase/setFinEntryAsPayed";
import setFinEntryAsConfirmed from "../../adaptors/firebase/setFinEntryAsConfirmed";

interface FinancialData {
  docId: string,
  amount: string,
  objective: string,
  creditor?: string,
  debtor?: string,
  payed: boolean,
  confirmed: boolean,
  cleared: string,
  date: any
}



const FinancialEntry = (props: {financialData: FinancialData, setNotificationOpen: any, type: string}): JSX.Element => {
  const handlePayClick = ({target}: any) => {
    const id = target.closest(".financial-entry").id;
    setFinEntryAsPayed(id)
      .then(resp => props.setNotificationOpen({open: true, message: "Eintrag wurde aktualisiert", type: "success"}))
      .catch(e => {
          console.log(e);
          props.setNotificationOpen({open: true, message: "Fehler beim Aktualisieren des Eintrags", type: "error"});
        }
      )
  }

  const handleConfirmClick = ({target}: any) => {
    const id = target.closest(".financial-entry").id;
    setFinEntryAsConfirmed(id)
      .then(resp => props.setNotificationOpen({open: true, message: "Eintrag wurde aktualisiert", type: "success"}))
      .catch(e => {
        console.log(e);
        props.setNotificationOpen({open: true, message: "Fehler beim Aktualisieren des Eintrags", type: "error"});
        }
      )
  }

  const dateObj = props.financialData.date.toDate();
  const date = `${("0" + dateObj.getUTCDate()).slice(-2)}.${("0"+(dateObj.getMonth()+1)).slice(-2)}.${dateObj.getFullYear()}`
  return(
    <Paper key={props.financialData.docId} id={props.financialData.docId} className={"f-col-fs-fs financial-entry"}>
      <Box className={"objective-amount"}>
        <Typography variant={"h5"}>{props.financialData.objective}</Typography>
        <Typography variant={"h5"}>{props.financialData.amount}</Typography>
      </Box>
      <Box>
        <Typography variant={"body1"}>{date}</Typography>
      </Box>
      <Box>
        <Typography variant={"body1"}>{props.type === "credit" ? props.financialData.debtor : props.financialData.creditor}</Typography>
      </Box>
      {props.type === "credit" ?
        !props.financialData.payed ? // For credits entries
          <Typography sx={{position: "absolute", bottom: 15, right: 15}} variant={"body1"} style={{fontStyle: "italic"}}>Pending</Typography>
          :
          <Button sx={{position: "absolute", bottom: 15, right: 15}} variant={"contained"} onClick={handleConfirmClick}>Confirm</Button>
        :
        !props.financialData.payed ? // For debt entries
          <Button sx={{position: "absolute", bottom: 15, right: 15}} variant={"contained"} onClick={handlePayClick}>Pay</Button> :
          <Typography sx={{position: "absolute", bottom: 15, right: 15}} variant={"body1"} style={{fontStyle: "italic"}}>Pending</Typography>
      }
    </Paper>
  )
}

export default FinancialEntry;