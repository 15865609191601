import {firestore, query, collection, getDocs, where, limit, updateDoc, doc} from "./"
import {Dispatch, SetStateAction} from "react";
import {getAuth, updateEmail} from "firebase/auth";

interface UserData {
  id: string,
  label: string,
  value: string,
  btnLabel: string,
  buttonClickFunc: any
}

const getUserData = async (uid: string, userData: UserData[], setUserData: Dispatch<SetStateAction<UserData[]>>) : Promise<void> => {
  const user = getAuth().currentUser;
  const userMail = user?.email ? user.email : "";
  console.log(userMail);
  const newUserData = userData;
  const emailIndex = newUserData.findIndex(val => val.id === "email");
  newUserData[emailIndex].value = userMail;
  console.log(newUserData);
  setUserData(newUserData);
}

const updateEmailAddress = async (email: string, setNotificationData: any) => {
  const user = getAuth().currentUser;
  const uid = user?.uid
  if (user) {
    const gquery = query(collection(firestore, "Users"), where("userId", "==", uid));
    const userDocs = await getDocs(gquery);
    const docId = userDocs.docs[0].id;
    updateEmail(user, email)
      .then((_) => {
        const userDoc = doc(firestore, `Users/${docId}`);
        updateDoc(userDoc, {
          email: email
        })
        setNotificationData({open: true, message: "Deine E-Mail Adresse wurde erfolgreich aktualisiert", type: "success"});
      })
      .catch((e) => {
        console.log("error during updating email");
        console.log(e);
        setNotificationData({open: true, message: "Deine E-Mail Adresse konnte nicht aktualisiert werden. " + e.message, type: "error"});

      })
  }
}

export {getUserData, updateEmailAddress}