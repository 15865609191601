import {getCountOfEntries, fbCreateFinancialEntry} from "../../adaptors/firebase/firestoreFinance";

interface FinancialEntryData {
  id: string,
  objective: string,
  amount: string,
  creditorId: string,
  creditor: string,
  debtor: string,
  invoiced: boolean,
  payed: boolean
  cleared: boolean,
  date: string,
  receiptUrl: string
}

const createFinancialEntry = async (data: FinancialEntryData, setNotificationOpen: any): Promise<any> => {
  const countOfFinEntries = await getCountOfEntries();
  data["id"] = (countOfFinEntries+1).toString();
  data["creditorId"] = "";
  data["invoiced"] = false;
  data["payed"] = false;
  data["cleared"] = false;
  data["date"] = "";
  data["receiptUrl"] = "";
  return fbCreateFinancialEntry(data)
    .then((resp) => {
      return resp
    })
    .catch(e => {
      console.log(e);
      setNotificationOpen({
        open: true,
        message: "Fehler beim Erstellen eines neuen Eintrags",
        type: "error"
      })
    })
}

export default createFinancialEntry;