import {collection, firestore, onSnapshot, orderBy, query, where} from "./index";
import {toTitleCase} from "../../modules/speed_dial_menu/stringManipulation";

interface FinancialEntry {
  amount: string,
  cleared: string,
  confirmed: boolean,
  creditor: string,
  creditorId: string,
  date: any
  debtor: string,
  id: string
  docId: string,
  invoiced: string,
  objective: string,
}

const getAllCreditsEntries = (creditor: string, setDebtsEntries: any): () => void => {
  const debtsQuery = query(collection(firestore, `Financials/2021/m1`), where("creditor", "==", toTitleCase(creditor)), orderBy("date", "desc"));
  return onSnapshot(debtsQuery, (col) => {
    const data  = col.docs.map((doc) => <FinancialEntry>({
      ...doc.data(),
      docId: doc.id
    }))
    const openDebts = data.filter(el => el.cleared !== "Yes" && !el.confirmed);
    setDebtsEntries(openDebts)
  }, error => console.log(error));
}

export default getAllCreditsEntries;