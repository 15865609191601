import {getAuth, signOut} from "./";

const firebaseSignOut = async () => {
  try {
    const auth = getAuth();
    return await signOut(auth);
  } catch (e) {
    console.log("Error during sign out:", e);
  }
}

export {firebaseSignOut};