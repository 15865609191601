import {firestore, collection, addDoc} from "./index"

const createUserDocument = (data: any) => {
  addDoc(collection(firestore, `Users`), data)
    .then(resp => console.log("successfully created user doc"))
    .catch((e) => {
      console.log("Error during creation of user doc:");
      console.log(e);
    })
}

export default createUserDocument;