import {useState, useEffect} from "react";
import Hero from "../../Hero";
import {Typography, Box} from "@mui/material";

import SettingsPanel from "./SettingsPanel";
import EmailForm from "./EmailForm";
import Notification from "../../modules/notification/Notification";
import {getUserData} from "../../adaptors/firebase/firebaseUserPage";
import {useFbAuth} from "../../adaptors/auth/useAuth";
import "./userpage.css"

interface UserData {
  id: string,
  label: string,
  value: string,
  btnLabel: string,
  buttonClickFunc: any
}
interface _Notification {
  open: boolean,
  message: string,
  type: any
}

const UserPage = () => {

  const handleModalOpen = () => {
    setModalOpen(true);
  }
  const handleModalClose = () => {
    setModalOpen(false);
  }

  const {user, isLoading} = useFbAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<UserData[]>([
    {
      id: "username",
      label: "Name",
      value: "",
      btnLabel: "Edit",
      buttonClickFunc: null
    },
    {
      id: "email",
      label: "E-Mail",
      value: "",
      btnLabel: "Edit",
      buttonClickFunc: handleModalOpen
    },
    {
      id: "passwort",
      label: "Passwort",
      value: "******",
      btnLabel: "Edit",
      buttonClickFunc: null
    },
  ]);
  const [error, setError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<_Notification>({
    open: false,
    message: "",
    type: "success"
  });

  useEffect(() => {
    try {
      if (user  && Object.entries(user).length !== 0) {
        const uid: string = user.uid;
        getUserData(uid, userData, setUserData)
          .then(_ => setLoading(false))
          .catch((e) => {
            setError(e.message);
            console.log("Error in getUserData:", e.message);
          })
        // setLoading(false);
      }
    } catch (e) {
      console.log(e)
    }
  }, [user, userData])

  if (!loading && !error) {
    return (
      <div className={"page-root"}>
        <Hero title={"Account Übersicht"} />
        <EmailForm modalOpen={modalOpen} handleModalClose={handleModalClose} setNotificationData={setNotificationData} />
        <Box className={"content-root"}>
          {userData.map((data) => (
            <SettingsPanel key={data.id} id={data.id} settingsLabel={data.label} settingsValue={data.value} buttonLabel={data.btnLabel} handleButtonClick={data.buttonClickFunc}  />
          ))}
        </Box>
        <Notification data={notificationData} setNotification={setNotificationData} />
      </div>
    )
  } else {
    return (
      <div>
        <Typography variant={"h5"}>Loading...</Typography>
      </div>
    )
  }
}

export default UserPage;