import {fbSignIn} from "../../adaptors/firebase/firebaseSignIn";

interface SignInObj {
  email: string,
  password: string
}
interface Notification {
  open: boolean,
  message: string,
  type: any
}
const signIn = (values: SignInObj, setNotification: (data: Notification) => void, navigate: any) => {
  fbSignIn(values)
    .then((resp) => {
      if (resp.user) {
        setNotification({open: true, message: "Anmeldung erfolgreich", type: "success"});
        navigate("/cleaningplan");
      }
    })
    .catch((e) => {
      console.log(e.message);
      setNotification({open: true, message: e.message, type: "error"});
    })
}

export {signIn}